import './bootstrap';
import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'
import { Layout,Login } from 'laravel-inertia-backend';
import './bootstrap';
import '../scss/app.scss';
import { LaravelReactI18nProvider } from 'laravel-react-i18n';
import menu from "./menu.js";
import {version} from "./version.js";
import HeaderNavExtraComponent from "./Components/HeaderNavExtraComponent.jsx";
import {Image} from "react-bootstrap";

createInertiaApp({
    resolve: name => {
        if (name === 'Auth/Login') {
            return () => <Login/>;
        }
        const pages = import.meta.glob('./Pages/**/*.jsx', { eager: true })
        let page = pages[`./Pages/${name}.jsx`]
        page.default.layout =
            name.startsWith("Auth/") || name == "Welcome"
                ? undefined
                : (page) => <Layout
                    menuEntries={menu}
                    title="Molotow Ordermail 2"
                    titleElement={(<Image src="/images/logo_bg_dark.svg" alt="ordermail 2" style={{height: "1.5em"}}/>)}
                    sidebarBottomComponent={(<>Version: {version}</>)}
                    headerNavExtraComponent={<HeaderNavExtraComponent/>}
                    children={page} />;
        return page
    },
    setup({ el, App, props }) {
        createRoot(el).render(<LaravelReactI18nProvider
            locale={'de'}
            files={import.meta.glob('/resources/lang/*.json')}
        ><App {...props} />
        </LaravelReactI18nProvider>
        )
    },
})
